import { makeStyles } from "@material-ui/core/styles";

export const TextStyles = makeStyles((theme) => ({
  extraLargeText: {
    fontSize: "xx-large",
    [theme.breakpoints.down("md")]: {
      fontSize: "x-large",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "large",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "80%",
    },
  },
  largeText: {
    fontSize: "large",
    [theme.breakpoints.down("md")]: {
      fontSize: "large",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "medium",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "50%",
    },
  },
  mediumText: {
    fontSize: "medium",
    [theme.breakpoints.down("md")]: {
      fontSize: "small",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "xx-small",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "4px",
    },
  },
  smallText: {
    fontSize: "small",
    [theme.breakpoints.down("md")]: {
      fontSize: "small",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "xx-small",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "4px",
    },
  },
}));

export const PositionStyles = makeStyles((theme) => ({
  CharacterName: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "4.75px",
    },
  },
  ClassName: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "4.75px",
      top: "51%",
      width: "28%",
    },
  },
  ClassFeatures: {
    // [theme.breakpoints.down('xs')]: {
    //     left: "6%",
    //     width: "28%",
    //     lineHeight: "1.05",
    // },
  },
  LuckDie: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "6px",
      top: "70.75%",
    },
  },
  Luck: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "9px",
      top: "77%",
    },
  },
  Cash: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "9px",
    },
  },
  HitPoints: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "9px",
    },
  },
  Weapon: {
    // [theme.breakpoints.down('xs')]: {
    //     top: "8.5%",
    //     left: "58%",
    //     maxWidth: "33%",
    //     backgroundColor: "white",
    // },
  },
  Armor: {
    // [theme.breakpoints.down('xs')]: {
    //     top: "14%",
    //     left: "58%",
    //     maxWidth: "33%",
    // },
  },
  Strength: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "9px",
      top: "30%",
    },
  },
  Agility: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "9px",
      top: "38%",
    },
  },
  Presence: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "9px",
      top: "45.75%",
    },
  },
  Toughness: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "9px",
      top: "53.25%",
    },
  },
  Knowledge: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "9px",
      top: "61%",
    },
  },
}));
